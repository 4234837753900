.anglelogo {
    img{ 
    width: 100px;
    margin-left: 10px;
}}

.modal-header .btn-close {
    margin: -0.4rem -0.7rem -0.4rem 0 !important;
}
.modal-title {
    margin: auto;
}

.TracksLoader {
    display: flex;
    align-items: center;
    justify-content: center; 
    height: 74vh; 
    .spinner-border{
        width: 50px;
        height: 50px;
    }
  }



button.outline-primary-btn {
    border: 2px solid #0d89ef; // don't change color 
    border-radius: 10px;
    font-weight: 500;
}

.form-switch{
    display: flex;
    align-items: center;
}
.sellbtn {
    padding: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    margin: 10px 0;
    border: 1px solid #024073;
    img {
        max-width: 25px;
    }
    p{
        margin: 0;
    }
}

span.teleLink {
    max-width: 250px;
    word-break: break-all;
    display: flex;
    background: #eee;
    padding: 10px;
    border-radius: 10px;
    cursor: pointer;
}
.boxlanding {
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    border-radius: 10px;
    border: 1px solid;
    box-shadow: 0 0 10px;
    h2.titlelanding {
        color: #024073;
        span{
            color: #f00;
        }
    }
    p{
        color: #000;
        margin: 15px 0;
        min-height: 150px;
    }
    img{
        height: 200px;
    }

    a.showdetails {
        background: #024073;
        display: block;
        width: 100%;
        text-align: center;
        padding: 15px 0;
        color: #fff;
    }

}